<template>
  <el-row>
    <el-form label-width="120px">
      <el-form-item label="" style="margin-bottom: 5px;">
        <l-datepicker type="date" v-model="selDay" style="width:140px;margin-right:10px;"></l-datepicker>
        <el-button type="warning" icon="el-icon-plus" size="mini" @click="add">添加</el-button>

        <span class="padding-lr-xs">要求</span>
        <l-select v-model="dataInfo.dayC" :all="false" :data="$constant.BaseDays" @change="refresh()" style="width:80px"></l-select>
        <span class="padding-lr-xs text-bold text-green">内完成</span>,

        <span class="padding-lr-xs">提前</span>
        <el-input-number v-model="dataInfo.dayB" size="mini" :step="1" step-strictly @change="refresh()" style="width:100px"></el-input-number>
        <span class="padding-lr-xs">日</span>
        <span class="padding-lr-mini text-bold text-blue">生成</span>
        <span class="padding-lr-xs">可执行任务</span>
      </el-form-item>
    </el-form>
    <div style="margin-left: 120px;">
      <div class="fixed-item" v-for="(item, index) in dataInfo.dayList" :key="index" @click="remove(item)">
        <p>
          <span>{{ item }}</span
          ><a class="el-icon-delete-solid"></a>
        </p>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selDay: ''
    }
  },
  mounted() {},
  methods: {
    add() {
      if (this.selDay) {
        this.dataInfo.dayList.push(this.selDay)
        this.dataInfo.days = this.dataInfo.dayList.join(',')
      }
      this.selDay = ''
      this.refresh()
    },
    remove(item) {
      this.$lgh.msgConfirm('确认移除' + item + '?', () => {
        var list = this.dataInfo.dayList.filter(a => a !== item)
        this.dataInfo.days = list.join(',')
        this.refresh()
      })
    },
    refresh(reset) {
      this.$emit('refresh', {
        data: this.dataInfo,
        reset: !!reset
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fixed-item {
  display: inline-block;
  width: 100px;
  padding: 5px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 2px;
  background-color: rgba(64, 158, 255, 0.7);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  a {
    display: none;
    margin-left: 8px;
    color: red;
  }
}
.fixed-item :hover {
  a {
    display: inline;
  }
}
</style>
